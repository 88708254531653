import { FETCH_EMAIL_TRACKING_TRANSACTIONS } from './types'

const initialState = {
    emailTransactions: []
}

const emailTrackingReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EMAIL_TRACKING_TRANSACTIONS:
            return { ...state, emailTransactions: action.payload }
        default:
            return state
    }
}

export default emailTrackingReducer