import React, { useRef, useState, useEffect } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Input, Space, Table, DatePicker, Select, Popover } from 'antd'
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { PNSQueueLogsTableColumns, PNS_QUEUE_LOGS_STATUS } from './pnsqueue-logs.columns'
import { TRANSACTION_DATE_FORMAT, transformUserIdFormat } from '../Transactions/Transactions.const'
import { DEFAULT_PAGE_SIZE, PAGINATION, renderJsonPreview, showTotalRecords } from '../../../const/utils'
import { fetchPNSQueueLogsTransactions } from '../../../store/pnsQueueLogs/actions'

export const PNSQueueLogsTransactions = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const transactions = useSelector(state => state?.pnsQueueLogs?.pnsQueueLogs)
  const searchInput = useRef(null)
  const filterStatuses = Object.values(PNS_QUEUE_LOGS_STATUS)
  const [currentPagination, setCurrentPagination] = useState(PAGINATION)
  const [prepairActivityActionsTableColumns, setPrepairActivityActionsTableColumns] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const handleColumnSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Space>
          <Button
            type="primary"
            className='mx-2 bg-[#207868]'
            onClick={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilter: (value, record) => {
      if (record[dataIndex]) {
        if (dataIndex === 'status') {
          return record[dataIndex]?.props.children.toLowerCase().includes(value.toLowerCase())
        } else {
          return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        }
      } else {
        return false
      }

    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    }
  })

  useEffect(() => {
    dispatch(fetchPNSQueueLogsTransactions())
    const newActivityActionsTableColumns = PNSQueueLogsTableColumns.map((item) => {
      if (item.notAllowToSearch === true) {
        return item
      } else {
        return {
          ...item,
          ...getColumnSearchProps(item['dataIndex'])
        }
      }
    })
    setPrepairActivityActionsTableColumns(newActivityActionsTableColumns)
  }, [])

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [filterDateRange, setFilterDateRange] = useState(null)
  const [filterStatus, setFilterStatus] = useState(null)
  // const filterStatuses = Object.values(ACTIVITY_VALIDATION_METHOD_STATUS)
  // const [selectedRows, setSelectedRows] = useState([])
  const [wordEntered, setWordEntered] = useState()

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
    // console.log(newSelectedRowKeys)
    // setSelectedRows(newSelectedRowKeys.map(i => transactions?.result[i]))
  }

  const rowSelection = {
    ...selectedRowKeys,
    // ...selectedRows,
    onChange: onSelectChange
  }

  const handleSearch = (event) => {
    const searchWord = event.target.value
    setWordEntered(searchWord)
  }

  const handleStatusFilter = (value) => {
    // console.log(value)
    setFilterStatus(value)
  }


  const _handleSearchAndFilter = (pagination = PAGINATION) => {
    const params = {
      ...pagination
    }
    const _current = JSON.parse(JSON.stringify(currentPagination))
    _current.current = pagination?.current || PAGINATION.current
    _current.pageSize = pagination?.pageSize || PAGINATION.pageSize
    setCurrentPagination(_current)
    if (!!wordEntered) {
      params['search'] = wordEntered.toLowerCase()
    }
    if (filterDateRange) {
      params['fromDate'] = moment(new Date(filterDateRange[0])).format(`DD/MM/YYYY`)
      params['toDate'] = moment(new Date(filterDateRange[1])).format(`DD/MM/YYYY`)
    }
    if (filterStatus) {
      params['status'] = filterStatus
    }
    dispatch(fetchPNSQueueLogsTransactions(params))
  }

  const handleTableChange = (pagination) => {
    _handleSearchAndFilter(pagination)
  }

  const _handleFromToSelected = (v) => {
    // console.log(`_handleFromToSelected `, v)
    setFilterDateRange(v)
  }

  const hasSelected = selectedRowKeys.length > 0 || (transactions && transactions?.result?.length > 0)

  return (
    <div className='bg-white shadow'>
      <PageHeader
        ghost={false}
        title="PNS Queue Logs"
      ></PageHeader>

      <div className='flex items-center justify-between mr-5 mb-5'>
        <div className='flex'>
          {/* <Input
            className='w-[220px] h-[40px] ml-4 mr-2'
            placeholder='Search Email'
            value={wordEntered}
            onChange={handleSearch}
            prefix={<SearchOutlined />}
          /> */}
          <DatePicker.RangePicker onChange={(v) => _handleFromToSelected(v)} />
          <Select
            // bordered={false}
            className='w-[100px] h-[40px] mx-2'
            placeholder='Status'
            name='status'
            allowClear
            onChange={handleStatusFilter}
            options={filterStatuses.map(i => ({ label: i.label, value: i.value }))}
          />

          <Button className='py-5 mx-2 flex items-center' onClick={_handleSearchAndFilter}>Apply Search & Filter</Button>
        </div>
      </div>

      <Table
        className='z-[0] relative overflow-auto'
        // rowSelection={rowSelection}
        columns={prepairActivityActionsTableColumns}
        dataSource={transactions?.result?.map((i, it) => ({
          key: i._id,
          srno: (++it + ((currentPagination?.current - 1) * currentPagination?.pageSize)),
          userId: transformUserIdFormat(i?.userProfile),
          createdDate: i?.createdAt && (moment(i?.createdAt).format(TRANSACTION_DATE_FORMAT)),
          name: i?.userProfile?.name,
          email: i?.userProfile?.email,
          timezone: i?.timezone,
          // push_type: i?.push_type || JSON.parse(i?.payload)['push_type'],
          title: i?.title,
          time_of_message: i?.time_of_message,
          status: `${i?.status}`,
          message: `${i?.message}`,
          payload: <Popover content={renderJsonPreview(JSON.parse(i?.payload))} title="Payload Data">
            <p className='cursor-pointer text-blue-400 hover:text-blue-800 hover:underline'>View</p>
          </Popover>,
          updatedDate: i?.updatedAt && (moment(i?.updatedAt).format(TRANSACTION_DATE_FORMAT))
        }))}
        pagination={{
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          hideOnSinglePage: true,
          position: ["none", "bottomRight"],
          current: currentPagination?.current,
          total: transactions?.totalCount,
          showTotal: showTotalRecords
        }}
        onChange={handleTableChange}
      />
    </div>
  )
}
