import React, { useState, useEffect } from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Input, Table, DatePicker, Select, Popover, Tag, Flex } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { ZohoActionsTableColumns } from './zoho.columns'
import { DEFAULT_PAGE_SIZE, PAGINATION, renderJsonPreview, showTotalRecords } from '../../../const/utils'
import { fetchZohoTransactions } from '../../../store/zoho/actions'
import { TRANSACTION_DATE_FORMAT, ACTIVITY_VALIDATION_METHOD_STATUS } from '../Transactions/Transactions.const'

export const ZohoTransactions = () => {
  const dispatch = useDispatch()

  const transactions = useSelector(state => state?.zoho?.zohoTransactions)

  const [prepairActivityActionsTableColumns, setPrepairActivityActionsTableColumns] = useState(null)

  useEffect(() => {
    dispatch(fetchZohoTransactions())
    const newActivityActionsTableColumns = ZohoActionsTableColumns.map((item) => {
      if (item.notAllowToSearch === true) {
        return item
      } else {
        return {
          ...item
          // ...getColumnSearchProps(item['dataIndex'])
        }
      }
    })
    setPrepairActivityActionsTableColumns(newActivityActionsTableColumns)
  }, [])

  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [filterDateRange, setFilterDateRange] = useState(null)
  const [filterStatus, setFilterStatus] = useState(null)
  const filterStatuses = Object.values(ACTIVITY_VALIDATION_METHOD_STATUS)
  const [wordEntered, setWordEntered] = useState()

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    ...selectedRowKeys,
    // ...selectedRows,
    onChange: onSelectChange
  }

  const handleSearch = (event) => {
    const searchWord = event.target.value
    setWordEntered(searchWord)
  }

  const handleStatusFilter = (value) => {
    // console.log(value)
    setFilterStatus(value)
  }

  const _handleSearchAndFilter = (pagination = PAGINATION) => {
    const params = {
      ...pagination
    }
    if (!!wordEntered) {
      params['search'] = wordEntered.toLowerCase()
    }
    if (filterDateRange) {
      params['fromDate'] = moment(new Date(filterDateRange[0])).format(`DD/MM/YYYY`)
      params['toDate'] = moment(new Date(filterDateRange[1])).format(`DD/MM/YYYY`)
    }
    if (filterStatus) {
      params['status'] = filterStatus
    }
    dispatch(fetchZohoTransactions(params))
  }

  const handleTableChange = (pagination) => {
    _handleSearchAndFilter(pagination)
  }

  const _handleFromToSelected = (v) => {
    // console.log(`_handleFromToSelected `, v)
    setFilterDateRange(v)
  }

  // const hasSelected = selectedRowKeys.length > 0 || (transactions && transactions?.result?.length > 0)

  return (
    <div className='bg-white shadow'>
      <PageHeader
        ghost={false}
        title="ZOHO Transactions"
      ></PageHeader>
      <Flex gap="middle">
        <Flex gap="middle" vertical>
          <Flex gap="middle">
            <Input
              className='w-[450px] h-[40px] ml-4'
              placeholder='Search Activity Name, Email'
              value={wordEntered}
              onChange={handleSearch}
              prefix={<SearchOutlined />}
            />
            <DatePicker.RangePicker onChange={(v) => _handleFromToSelected(v)} />
          </Flex>
          <Flex gap="middle" className='mb-4'>
            <Select
              // bordered={false}
              className='w-[200px] h-[40px] ml-4'
              placeholder='Status'
              name='status'
              allowClear
              onChange={handleStatusFilter}
              options={filterStatuses.map(i => ({ label: i.label, value: i.value }))}
            >
              {/* <Select.Option value='COMPLETED'>COMPLETED</Select.Option>
            <Select.Option value='PENDING'>PENDING</Select.Option>
            <Select.Option value='APPROVED'>APPROVED</Select.Option>
            <Select.Option value='CANCELED'>CANCELED</Select.Option> */}
            </Select>

            {/* <Select
            name='goal'
            // className='py-5 mx-2 flex items-center'
            bordered={false}
            className='w-[150px] h-[40px] border-[1px] border-mainThemeColorBorder'
            placeholder='goal'
            optionFilterProp='label'
            options={goals.map(c => ({ label: c.name, value: c._id }))}
            // onChange={handleCommunityFilter}
            allowClear
          /> */}
          </Flex>
        </Flex>
        <Flex gap="middle">
          <Button className='w-[200px] h-[40px]' onClick={_handleSearchAndFilter}>Apply Search & Filter</Button>
        </Flex>
      </Flex>
      <Table
        className='z-[0] relative overflow-auto'
        rowSelection={rowSelection}
        columns={prepairActivityActionsTableColumns}
        dataSource={transactions?.result?.map((i, it) => ({
          key: i._id,
          srno: ++it,
          transactionId: i.transactionId,
          submittedData: i?.createdAt && (moment(i?.createdAt).format(TRANSACTION_DATE_FORMAT)),
          name: i?.user_id?.name,
          email: i?.user_id?.email,
          zohoType: <>{i?.zoho_type}<Tag>{i?.activity_id?.activityType}</Tag></>,
          activityName: i?.activity_id?.name,
          callbackData: <Popover content={renderJsonPreview(JSON.parse(i?.meta_data))} title="Callback Data">
            <p className='cursor-pointer text-blue-400 hover:text-blue-800 hover:underline'>View</p>
          </Popover>
        }))}
        pagination={{
          defaultPageSize: DEFAULT_PAGE_SIZE,
          showSizeChanger: true,
          hideOnSinglePage: true,
          position: ["none", "bottomRight"],
          total: transactions?.totalCount,
          showTotal: showTotalRecords
        }}
        onChange={handleTableChange}
      />
    </div>
  )
}
