import ServerApi from "../../utility/ServerApi"
import { toggleNetworkLoading } from "../common/actions"
// import { message } from 'antd'
// import moment from 'moment'
import { FETCH_EMAIL_TRACKING_TRANSACTIONS } from "./types"

export const fetchEmailTrackingTransactions = (filters) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/email-tracking/email-transactions?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}${filters?.teamId ? `&teamId=${filters?.teamId}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`).then((res) => {
        dispatch({
            type: FETCH_EMAIL_TRACKING_TRANSACTIONS,
            payload: res.data
        })
        dispatch(toggleNetworkLoading(false))
    }).catch((err) => {
        dispatch(toggleNetworkLoading(false))
        console.log('[fetchEmailTrackingTransactions] ----err----- ', err)
    })
}