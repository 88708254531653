import { FETCH_CHALLENGE_COMPLETED_REWARD_TRANSACTIONS, FETCH_CHALLENGE_COMPLETED_VOUCHER_REWARD_TRANSACTIONS, FETCH_COMMUNITY_IMPACT_REWARD_TRANSACTIONS, FETCH_QUEST_COMPLETED_VOUCHER_REWARD_TRANSACTIONS, FETCH_REWARD_LIST_TYPE, FETCH_REWARD_TRANSACTIONS } from "./types"
import { toggleNetworkLoading } from "../../common/actions"
import { message } from 'antd'
import ServerApi from "../../../utility/ServerApi"
import { PAGINATION } from "../../../const/utils"
import ServerApiCSV from "../../../utility/ServerApiCSV"
import moment from 'moment'

/**
 * #45721 - Reward Transaction - add option to allow the admin reject the transaction
 * @param {*} _selected_rows 
 * @param {*} callback 
 * @returns 
 */
export const handleRejectThisRewardTransaction = (_selected_rows, callback) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().post(`/rewards/reject-this-reward-transaction`, { selected_rows: _selected_rows })
        .then(res => {
            message.success("Transaction rejected!")
            if (callback) callback()
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            console.error(e)
            if (callback) callback()
        })
}

/**
 * 
 * @param {*} filters 
 * @param {*} callback 
 * @returns 
 */
export const fetchRewardTransactions = (filters = PAGINATION, callback = null) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`rewards/redeem-rewards-transactions?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.rewardsType ? `&rewardsType=${filters?.rewardsType}` : ''}${filters?.search ? `&search=${encodeURIComponent(filters?.search).replace(/%20/, "+")}` : ''}
    ${filters?.status ? `&status=${filters?.status}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`)
        .then(res => {
            dispatch({
                type: FETCH_REWARD_TRANSACTIONS,
                payload: res.data
            })
            if (callback) callback(res.data)
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            console.error(e)
            dispatch(toggleNetworkLoading(false))
            message.error("Error in fetching Reward Transactions", e)
        })
}

/**
 * 
 * @param {*} filters 
 * @param {*} callback 
 * @returns 
 */
export const fetchChallengeRewardsTransactions = (filters = PAGINATION, callback = null) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`rewards/challenge-completed-rewards-transactions?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.search ? `&search=${encodeURIComponent(filters?.search).replace(/%20/, "+")}` : ''}${filters?.status ? `&status=${filters?.status}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`)
        .then(res => {
            dispatch({
                type: FETCH_CHALLENGE_COMPLETED_REWARD_TRANSACTIONS,
                payload: res.data
            })
            if (callback) callback(res.data)
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in fetch challenge rewards transactions", e)
        })
}


export const fetchChallengeVoucherRewardsTransactions = (filters = PAGINATION, callback = null) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`voucher-tracking/transactions?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.search ? `&search=${encodeURIComponent(filters?.search).replace(/%20/, "+")}` : ''}${filters?.status ? `&status=${filters?.status}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`)
        .then(res => {
            dispatch({
                type: FETCH_CHALLENGE_COMPLETED_VOUCHER_REWARD_TRANSACTIONS,
                payload: res.data
            })
            if (callback) callback(res.data)
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in fetch challenge voucher rewards transactions", e)
        })
}
/**
 * #51868 - Challenge and Quest Voucher Rewards - allow export CSV data
 * @param {*} filters 
 * @param {*} _selected_rows 
 * @returns 
 */
export const fetchChallengeVoucherRewardsTransactionsExportCsvAll = (filters, _selected_rows = []) => dispatch => {
    // dispatch(toggleNetworkLoading(true))
    ServerApiCSV().post(`voucher-tracking/challenge-transactions-export-csv?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.search ? `&search=${encodeURIComponent(filters?.search).replace(/%20/, "+")}` : ''}${filters?.status ? `&status=${filters?.status}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`, { ...filters, selected_rows: _selected_rows })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `challenge-vouchers_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(error => {
            message.error("No data found!")
        })
}

/**
 * #51811 - Voucher Reward - Show user redeem email in dashboard
 * @param {*} filters 
 * @param {*} callback 
 * @returns 
 */
export const fetchQuestVoucherRewardsTransactions = (filters = PAGINATION, callback = null) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`voucher-tracking/quest-transactions?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.search ? `&search=${encodeURIComponent(filters?.search).replace(/%20/, "+")}` : ''}${filters?.status ? `&status=${filters?.status}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`)
        .then(res => {
            dispatch({
                type: FETCH_QUEST_COMPLETED_VOUCHER_REWARD_TRANSACTIONS,
                payload: res.data
            })
            if (callback) callback(res.data)
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in fetch quest voucher rewards transactions", e)
        })
}

export const fetchQuestVoucherRewardsTransactionsExportCsvAll = (filters, _selected_rows = []) => dispatch => {
    ServerApiCSV().post(`voucher-tracking/quest-transactions-export-csv?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.search ? `&search=${encodeURIComponent(filters?.search).replace(/%20/, "+")}` : ''}${filters?.status ? `&status=${filters?.status}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`, { ...filters, selected_rows: _selected_rows })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `quest-vouchers_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(error => {
            message.error("No data found!")
        })
}

export const fetchRewardsListType = (callback) => dispatch => {
    // dispatch(toggleNetworkLoading(true))
    ServerApi().get(`reward-lists/filters-enum`)
        .then(res => {
            dispatch({
                type: FETCH_REWARD_LIST_TYPE,
                payload: res.data
            })
            if (callback) callback(res.data)
            // dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            // dispatch(toggleNetworkLoading(false))
            // message.error("Error in Fetching Reward Transactions", e)
        })
}

/**
 * 
 * @returns 
 */
export const exportRewardTransactionsAsCSV = (filters, _selected_rows) => dispatch => {
    ServerApiCSV().post(`/rewards/redeem-rewards-transactions-export-csv-all?${filters?.rewardsType ? `rewardsType=${filters?.rewardsType}` : ''}${filters?.search ? `&search=${encodeURIComponent(filters?.search).replace(/%20/, "+")}` : ''}${filters?.status ? `&status=${filters?.status}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`, { ...filters, selected_rows: _selected_rows })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `redeem-rewards-transactions_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(e => {
            message.error("No data found!")
            // dispatch(toggleNetworkLoading(false))
            console.error(e)
        })
}

/**
 * 
 * @param {*} _selected_rows 
 * @returns 
 */
export const exportChallengeRewardTransactionsAsCSV = (filters, _selected_rows) => dispatch => {
    ServerApiCSV().post(`/rewards/challenge-rewards-transactions-export-csv-all?${filters?.search ? `&search=${encodeURIComponent(filters?.search).replace(/%20/, "+")}` : ''}${filters?.status ? `&status=${filters?.status}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}`, { selected_rows: _selected_rows })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `challenge-rewards-transactions_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(e => {
            message.error("Error Fetching data!")
            // dispatch(toggleNetworkLoading(false))
            console.error(e)
        })
}

/**
 * #44868 - 5 May - URGENT - UBS Challenge duplicated rewards
 * @param {*} callback 
 * @param {*} _selected_rows 
 * @returns 
 */
export const handleChallengeRewardTransactionsRejectSelected = (_selected_rows, callback) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().post(`/rewards/challenge-rewards-transactions-reject-all`, { selected_rows: _selected_rows })
        .then(res => {
            message.success("Transactions rejected!")
            if (callback) callback()
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            console.error(e)
            if (callback) callback()
        })
}

/**
 * #44868 - 5 May - URGENT - UBS Challenge duplicated rewards
 * @param {*} filters 
 * @param {*} callback 
 * @returns 
 */
export const fetchCommunityImpactRewardsTransactions = (filters = PAGINATION, callback = null) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().get(`/community-impactrewards/mycommunity-rewards-transactions?current=${filters?.current || 1}&pageSize=${filters?.pageSize || 100}${filters?.search ? `&search=${encodeURIComponent(filters?.search).replace(/%20/, "+")}` : ''}${filters?.status ? `&status=${filters?.status}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}`)
        .then(res => {
            dispatch({
                type: FETCH_COMMUNITY_IMPACT_REWARD_TRANSACTIONS,
                payload: res.data
            })
            if (callback) callback(res.data)
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            message.error("Error in Fetching Reward Transactions", e)
        })
}

/**
 * #44868 - 5 May - URGENT - UBS Challenge duplicated rewards
 * @param {*} callback 
 * @param {*} _selected_rows 
 * @returns 
 */
export const handleCommunityImpactRewardsTransactionsRejectSelected = (_selected_rows, callback) => dispatch => {
    dispatch(toggleNetworkLoading(true))
    ServerApi().post(`/community-impactrewards/mycommunity-rewards-transactions-reject-all`, { selected_rows: _selected_rows })
        .then(res => {
            message.success("Transactions rejected!")
            if (callback) callback()
            dispatch(toggleNetworkLoading(false))
        })
        .catch(e => {
            dispatch(toggleNetworkLoading(false))
            console.error(e)
            if (callback) callback()
        })
}


/**
 * 
 * @param {*} _selected_rows 
 * @returns 
 */
export const exportCommunityImpactRewardsTransactionsAsCSV = (filters, _selected_rows) => dispatch => {
    ServerApiCSV().post(`/community-impactrewards/mycommunity-rewards-transactions-export-csv-all?${filters?.search ? `&search=${encodeURIComponent(filters?.search).replace(/%20/, "+")}` : ''}${filters?.status ? `&status=${filters?.status}` : ''}${filters?.fromDate ? `&fromDate=${filters?.fromDate}&toDate=${filters.toDate}` : ''}${filters?.communityId ? `&communityId=${filters?.communityId}` : ''}`, { selected_rows: _selected_rows })
        .then(res => {
            const url = window.URL.createObjectURL(new Blob([res.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `mycommunity-rewards-transactions_${moment().format('DD-MM-YYYY')}.csv`)
            document.body.appendChild(link)
            link.click()
        })
        .catch(e => {
            message.error("Error Fetching data!")
            // dispatch(toggleNetworkLoading(false))
            console.error(e)
        })
}