export const EmailTrackingTransactionsTableColumns = [
  {
    title: 'No',
    dataIndex: 'srno',
    key: 'srno',
    notAllowToSearch: true
  },
  {
    title: 'User',
    dataIndex: 'user_info',
    key: 'user_info'
  },
  {
    title: 'To Address',
    dataIndex: 'to_address',
    key: 'to_address'
  },
  {
    title: 'Subject',
    dataIndex: 'subject',
    key: 'subject'
  },
  {
    title: 'CTA From',
    dataIndex: 'cta_from',
    key: 'cta_from'
  },
  {
    title: 'CTA Content',
    dataIndex: 'cta_content',
    key: 'cta_content'
  },
  {
    title: 'Request Params',
    dataIndex: 'requestParams',
    key: 'requestParams'
  },
  {
    title: 'Response Data',
    dataIndex: 'responseData',
    key: 'responseData'
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt'
  }
]
