import React, { useRef, useEffect, useState } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Table, Space, Input, Tooltip, Popconfirm, Tag, Select, Flex } from 'antd'
import { SearchOutlined, DeleteOutlined, QuestionCircleOutlined, EyeOutlined, PlusOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchAllAdminUsers } from '../../../store/users/adminUsers/actions'
import { deleteUser, forceReset2FAThisUser } from '../../../store/users/action'
// import { fetchAllCommunity } from '../../../store/community/actions'
// import PreviewImage from '../../../components/image/PreviewImage'
// import { CSVLink } from 'react-csv'
import moment from 'moment'
// import { exportData } from './Users.const'
import { AdminUsersTableColums } from './Users.columns'
import { SUSGAIN_ROLES } from '../../../const/roles'
import { BE_FULL_DATE_FORMAT, BE_SHORT_DATE_FORMAT, DEFAULT_PAGE_SIZE, PAGINATION } from '../../../const/utils'
import { findUserPlatformFromMeta } from './Users.const'

export const AdminUsers = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const adminUsers = useSelector(state => state.adminUsers.adminUsers)

    const [wordEntered, setWordEntered] = useState('')

    const [roles, setRoles] = useState(Object.values(SUSGAIN_ROLES))

    const transformRole = (_role) => {
        return roles.find(item => item.role == _role).label
    }

    /**
     * TODO: move this checking to API
     * @param {*} _role 
     * @returns 
     */
    const isAdminRole = (_role) => {
        return _role == SUSGAIN_ROLES.ADMIN.role
    }
    const searchInput = useRef(null)

    const [prepairAdminUserColumns, setPrepairAdminUserColumns] = useState(null)
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState('')

    const handleColumnSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = (clearFilters) => {
        clearFilters()
        setSearchText('')
    }

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block'
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        className='mx-2 bg-[#207868]'
                        onClick={() => handleColumnSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined
                }}
            />
        ),
        onFilter: (value, record) => {
            if (record[dataIndex]) {
                if (dataIndex === 'type') {
                    return record[dataIndex]?.props?.children.toLowerCase().includes(value.toLowerCase())
                } else {
                    return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                }
            } else {
                return false
            }

        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100)
            }
        }
    })

    useEffect(() => {
        dispatch(fetchAllAdminUsers())
        const newAdminUserColumns = AdminUsersTableColums.map((item) => {
            if (item.notAllowToSearch === true) {
                return item
            } else {
                return {
                    ...item
                    // ...getColumnSearchProps(item['dataIndex'])
                }
            }
        })
        setPrepairAdminUserColumns(newAdminUserColumns)
    }, [])

    const handleSearch = (event) => {
        const searchWord = event.target.value
        setWordEntered(searchWord)
        // if (searchWord === '') {
        //     dispatch(fetchAllAdminUsers())
        //     return
        // }
        // dispatch(fetchAllAdminUsers({ search: searchWord.toLowerCase() }))
    }

    const deleteThisUser = (id) => {
        dispatch(deleteUser(id))
        return new Promise(resolve => {
            setTimeout(() => resolve(), 2000)
        })
    }

    const _handleForceReset2FAThisUser = (id) => {
        dispatch(forceReset2FAThisUser(id, () => {
            dispatch(fetchAllAdminUsers())
        }))
    }

    const _handleSearchAndFilter = (pagination = PAGINATION) => {
        const params = {
            ...pagination
        }
        if (!!wordEntered) {
            params['search'] = wordEntered.toLowerCase()
        }
        dispatch(fetchAllAdminUsers(params))
    }

    const _user2FAStatus = (_status) => {
        if (_status === true) {
            return <CheckCircleOutlined className='text-green-500' />
        }
        return <CloseCircleOutlined className='text-red-500' />
    }

    return (
        <div className='bg-white'>
            <PageHeader
                ghost={false}
                title="Manage Account Login Dashboard"
                extra={<Button type="primary" className="bg-[#207868]" onClick={() => navigate('/users/admin/add')}>Add New Account</Button>}
            ></PageHeader>
            <Flex gap="middle">
                <Flex gap="middle" className='mb-4 ml-4'>
                    <Input
                        className='w-[450px] h-[40px]'
                        placeholder='Search'
                        defaultValue={wordEntered}
                        allowClear
                        onChange={handleSearch}
                        prefix={<SearchOutlined />}
                    />
                </Flex>
                <Flex gap="middle">
                    <Button className='w-[200px] h-[40px]' onClick={_handleSearchAndFilter}>Apply Search & Filter</Button>
                </Flex>
            </Flex>
            <Table
                className='z-[0] relative w-full overflow-auto'
                // rowSelection={rowSelection}
                columns={prepairAdminUserColumns}
                dataSource={adminUsers?.result?.map((i, it) => ({
                    key: i._id,
                    srno: ++it,
                    name: i.name,
                    email: i.email,
                    role: transformRole(i.role),
                    platform: findUserPlatformFromMeta(i?.x_app_platforms),
                    community: i?.community?.map(i => <Tag>{i?.name}</Tag>),
                    lastActiveAt: i.lastActiveAt && (moment(i.lastActiveAt).format(BE_FULL_DATE_FORMAT)),
                    '2faStatus': _user2FAStatus(i?.is2FAEnabled),
                    createdAt: i.createdAt && (moment(i.createdAt).format(BE_SHORT_DATE_FORMAT)),
                    action:
                        <div style={{ minWidth: '65px' }}>
                            {/* <Tooltip title="Info">
                            <Button style={{ marginRight: "8px" }} onClick={() => { navigate((`/`)) }} size="small" type="ghost" shape="circle" icon={<EyeOutlined />} />
                        </Tooltip> */}
                            {!isAdminRole(i.role) &&
                                <Popconfirm
                                    title="Are you sure?"
                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                    onConfirm={() => deleteThisUser(i._id)}
                                    onCancel={() => null}
                                    okText="Yes"
                                    okType="danger"
                                    placement="topLeft"
                                    cancelText="Cancel"
                                >
                                    <Button size="small" type="ghost" danger shape="circle" icon={<DeleteOutlined />} />
                                </Popconfirm>
                            }
                            {i?.is2FAEnabled === true &&
                                <Popconfirm
                                    title="Are you sure?"
                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                    onConfirm={() => _handleForceReset2FAThisUser(i._id)}
                                    onCancel={() => null}
                                    okText="Yes"
                                    okType="danger"
                                    placement="topLeft"
                                    cancelText="Cancel"
                                >
                                    <Button size="small" type={'link'}>Reset 2FA</Button>
                                </Popconfirm>
                            }

                        </div>

                }))}
                pagination={{
                    defaultPageSize: DEFAULT_PAGE_SIZE,
                    showSizeChanger: true,
                    hideOnSinglePage: true,
                    position: ["none", "bottomRight"]
                    // total: users?.totalCount
                }}
            // onChange={handleTableChange}
            ></Table>
        </div>
    )
}
