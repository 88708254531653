import { ACTIVITY_VALIDATION_METHOD } from "../views/admin/Activity/Activity.const"

export const BE_SHORT_DATE_FORMAT = "DD MMM YYYY"
export const BE_FULL_DATE_FORMAT = "DD MMM YYYY HH:mm"

export const DEFAULT_PAGE_SIZE = 100

export const DEFAULT_FILTERS = { pagination: { current: 1, pageSize: DEFAULT_PAGE_SIZE } }

export const PAGINATION = { current: 1, pageSize: DEFAULT_PAGE_SIZE }

export const ALLOW_BANNER_FILE_FORMAT = [
  'image/jpeg',
  'image/png',
  'image/gif'
]

export const ALLOW_GENERIC_FILE_FORMAT = [
  'image/jpeg',
  'image/png'
]

export const SUPPORTED_FILE_FORMAT_EXTRA = {
  BANNER: "Supported: .PNG, .JPG, .GIF, 354x154px, max 50MB",
  ACTIVITY_THUMB: 'Supported: .PNG 160x160px, max 5MB',
  ACTIVITY_MAIN: 'Supported: .PNG, .JPG, 1170x768px, max 50MB',
  HABITS_ICON: 'Supported: .PNG, .JPG, 200x200px, max 5MB',
  CHALLENGE_THUMB: 'Supported: .PNG, .JPG, 1200x690px, max 50MB',
  CHALLENGE_MAIN: 'Supported: .PNG, .JPG, 1050x690px, max 50MB',
  QUEST_ORG_LOGO: 'Supported: .PNG, .JPG, 1024x1024px, max 50MB',
  QUEST_ORG_BG: 'Supported: .PNG, .JPG, 1440x1800px, max 50MB',
  QUEST_THUMB: 'Supported: .PNG, .JPG, 1200x690px, max 50MB',
  QUEST_MAIN: 'Supported: .PNG, .JPG, 1050x690px, max 50MB',
  COMMUNITY_LOGO: 'Supported: .PNG, .JPG, 480x480px, max 50MB',
  COMMUNITY_BANNER: 'Supported: .PNG, .JPG, 1170x780px, max 50MB',
  MESSAGING_ICON: 'Supported: .PNG, .JPG, 200x200px, max 50MB',
  GPS_LOCATION_ICON: 'Supported: .PNG, .JPG, 200x200px, max 50MB',
  CITY_LOCATION_ICON: 'Supported: .PNG, .JPG, 200x200px, max 50MB'
}

export const isSupportedFileFormatForBanner = (filetype) => {
  if (!!filetype) {
    const check = ALLOW_BANNER_FILE_FORMAT.find(i => i === filetype)
    return check
  }
  return false
}

export const isSupportedFileFormatForGeneric = (filetype) => {
  if (!!filetype) {
    const check = ALLOW_GENERIC_FILE_FORMAT.find(i => i === filetype)
    return check
  }
  return false
}

/**
 * 
 * @param {*} _method 
 * @returns 
 */
export const transformValidationMethod = (_method) => {
  try {
    return Object.values(ACTIVITY_VALIDATION_METHOD).filter(i => i.value == _method)[0].label
  } catch (e) {

  }
  return _method
}

const emailRegex = /^[-!#$%&'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/

export const isEmailValid = (email) => {
  if (!email) {
    return false
  }

  if (email.length > 254) {
    return false
  }

  const valid = emailRegex.test(email)
  if (!valid) {
    return false
  }

  // Further checking of some things regex can't handle
  const parts = email.split("@")
  if (parts[0].length > 64) {
    return false
  }

  const domainParts = parts[1].split(".")
  if (domainParts.some(
    function (part) {
      return part.length > 63
    })) {
    return false
  }
  return true
}

/**
 * 
 * @param {*} roles 
 * @returns 
 */
export const canIAccessThisWithMyRoles = (roles) => {
  try {
    const { user } = JSON.parse(sessionStorage.getItem("userData"))
    const check = roles.includes(user.role)
    // console.log(`canAccess ${user.role} check ${check} roles: `, roles)
    return check
  } catch (e) {
    return false
  }
}

export const currentUserRole = () => {
  try {
    const { user } = JSON.parse(sessionStorage.getItem("userData"))
    return user.role
  } catch (e) {
    return false
  }
}

import * as moment from "moment-timezone"

export const DEFAULT_TZ = () => {
  return 'Asia/Singapore'
}

/**
 * 
 * @param {*} timezone 
 */
export const momentWithTimezone = (timezone = null) => {
  const tx = !!timezone ? timezone : DEFAULT_TZ()
  return moment().tz(tx)
}

/**
 * unix timestamp
 * @param {*} tz 
 */
export const unixToMoment = (tz) => {
  return moment(tz).tz(DEFAULT_TZ())
}

/**
 * 
 * @returns 
 */
export const getDateAsNowUTC = () => {
  return momentWithTimezone(DEFAULT_TZ()).utc()
}

/**
 * 
 */
export const monentNowWithoutTz = () => {
  return moment()
}

/**
 * 
 * @param str 
 * @param format 
 * @returns 
 */
export const stringToMonentNowWithoutTz = (str, format) => {
  return moment(str, format)
}

/**
 * 
 * @param utc_date 
 * @returns 
 */
export const parseUTCToMomentDate = (utc_date) => {
  return moment.tz(utc_date, DEFAULT_TZ())
}

/**
 * 
 * @param utc_date 
 * @returns 
 */
export const parseDateToMomentDate = (utc_date) => {
  return moment(utc_date, DEFAULT_TZ())
}

export const availableTimezones = () => {
  const txs = moment.tz.names()
  return txs
}

export const getDateFromWeek = (week, year) => {
  const startDateOfWeek = moment().day("Monday").year(year).week(week)
  const formatStartDateOfWeek = startDateOfWeek.format('DD/MM')
  const formatEndDateOfWeek = startDateOfWeek.add(6, 'days').format('DD/MM')
  return `(${formatStartDateOfWeek.concat(" - ").concat(formatEndDateOfWeek)})`
}

/**
 * 
 * @param {*} total 
 * @returns 
 */
export const showTotalRecords = (total) => {
  return `Total ${total} records`
}

/**
 * 
 * @param {*} _obj 
 * @param {*} _keysToRemove 
 * @returns 
 */
export const omitKeysFromObject = (_obj, _keysToRemove = []) => {
  if (_obj) {
    const thisIsObject = Object.keys(_obj)
      .filter(key => _keysToRemove.indexOf(key) < 0)
      .reduce((obj, key) => {
        obj[key] = _obj[key]
        return obj
      }, {})
    return thisIsObject
  }
  return null
}

/**
 * 
 * @param {*} data 
 * @returns 
 */
export const renderJsonPreview = (data) => {
  return <div><pre>{JSON.stringify(data, null, 2)}</pre></div>
}