import { Action } from "history"
import { FETCH_CHALLENGE_COMPLETED_REWARD_TRANSACTIONS, FETCH_CHALLENGE_COMPLETED_VOUCHER_REWARD_TRANSACTIONS, FETCH_COMMUNITY_IMPACT_REWARD_TRANSACTIONS, FETCH_QUEST_COMPLETED_VOUCHER_REWARD_TRANSACTIONS, FETCH_REWARD_LIST_TYPE, FETCH_REWARD_TRANSACTIONS } from "./types"

const initialState = {
    rewardTransactions: [],
    voucherRewardTransactions: [],
    questVoucherRewardTransactions: [],
    rewardsListType: [],
    challengeRewardTransactions: [],
    communityImpactRewardTransactions: []
}

const rewardTransactionReducer = (state = initialState, aciton) => {
    switch (aciton.type) {
        case FETCH_REWARD_TRANSACTIONS:
            return { ...state, rewardTransactions: aciton.payload }
        case FETCH_CHALLENGE_COMPLETED_REWARD_TRANSACTIONS:
            return { ...state, challengeRewardTransactions: aciton.payload }
        case FETCH_CHALLENGE_COMPLETED_VOUCHER_REWARD_TRANSACTIONS:
            return { ...state, voucherRewardTransactions: aciton.payload }
            case FETCH_QUEST_COMPLETED_VOUCHER_REWARD_TRANSACTIONS:
                return { ...state, questVoucherRewardTransactions: aciton.payload }    
        case FETCH_COMMUNITY_IMPACT_REWARD_TRANSACTIONS:
            return { ...state, communityImpactRewardTransactions: aciton.payload }    
        case FETCH_REWARD_LIST_TYPE:
            return { ...state, rewardsListType: aciton.payload }
        default:
            return state
    }
}

export default rewardTransactionReducer